import React, { ReactElement, ReactNode, useEffect, useState } from "react";
import { useDomainContext } from "@hooks/useDomainContext";
import styles from "./style.module.scss";
import { useRouter } from "next/router";
import InsuranceOffer from "../insuranceOffer";
import { affiliateOfferZones } from "@lib/shared/config";
import Widget from "./widget";
import OffersColumn from "./widget/offersColumn";
import Offer from "@components/shared/offer";
import {
    getWidgetType,
    extractWidgets,
    removeParagraphById,
} from "@lib/sharedUtils";
import MortgageOffer from "../mortgageOffer";
import { useBingEnhancedConversion } from "@hooks/useBingEnhancedConversion";
import { cloneDeep } from "lodash";
import { ListingV3 } from "src/api/listings";
import { sha256 } from "js-sha256";

interface FieldsState {
    [x: string]: string;
}
export const decodeFieldsFromText = (
    text: string,
    fieldsState: FieldsState | null,
) => {
    let clonedText = text;

    if (!fieldsState) return clonedText;

    const dynamicStrings = text?.match(/\${([^}]*)}/g);
    // console.log("🔆💢🔆💢🔆 ~ fieldsState:", fieldsState);

    dynamicStrings?.forEach((item) => {
        if (fieldsState[item.replace("${", "").replace("}", "")]) {
            const temp = clonedText.replace(
                item,
                fieldsState[item.replace("${", "").replace("}", "")],
            );
            clonedText = temp;
        }
    });

    return clonedText;
};

export default function ThankYou({
    insuranceListingHeader,
    customThankYouMessage,
    hideListingTitle,
    inForm = false,
    isPageBuilder = false,
    className,
}: {
    insuranceListingHeader?: string;
    customThankYouMessage?: ReactNode;
    hideListingTitle?: boolean;
    inForm?: boolean;
    isPageBuilder?: boolean;
    className?: string;
}): ReactElement {
    const { query } = useRouter();
    const { eb } = query;
    const {
        form,
        clicksOffers,
        visitDetails,
        thankYouOffers,
        thankyouListing,
        secondListing,
        domain,
    } = useDomainContext();
    const [successMessageArray, setSuccessMessageArray] = useState<string[]>(
        [],
    );
    const [widgets, setWidgets] = useState<string[] | null>([]);

    const renderList = (list: ListingV3) => (
        <div className={`w-full pt-6 mt-6`} style={{ background: "#fbfbfb" }}>
            {list.pageTitle && !hideListingTitle && (
                <h2 className="text-xl md:text-2xl text-center md:text-left px-4 md:px-6 font-bold">
                    {list.pageTitle}
                </h2>
            )}
            {list?.offers?.map((offer, index) => (
                <Offer
                    zone={affiliateOfferZones.THANK_YOU}
                    offer={offer}
                    key={index}
                    className={`mt-0 ${styles["offersList"]}`}
                    style={{
                        borderStyle: "bottom",
                        background: "transparent",
                        borderColor: "#eee",
                        buttonBackground: "#3EA636",
                        buttonForground: "#fff",
                    }}
                />
            ))}
        </div>
    );

    // const stickyOffer = useMemo(() => {
    //     if (thankYouOffers && thankYouOffers.length) {
    //         return thankYouOffers.find((offer) => offer.sticky);
    //     }
    //     return null;
    // }, [thankYouOffers]);

    const handleFormAnswers = () => {
        const formAnswers = JSON.parse(
            localStorage.getItem("globalFormValues") as string,
        ) as FieldsState | null;
        const phoneNumberHashed = sha256(formAnswers?.["phoneNumber"] || "");
        const emailHashed = sha256(formAnswers?.["email"] || "");
        // @ts-ignore
        window.formAnswers = {
            ...cloneDeep(formAnswers),
            phoneNumberHashed,
            emailHashed,
            externalId: sha256(visitDetails.visitId),
        };
    };

    useEffect(() => {
        const customDomainThankYouMessage = domain?.textBlocks?.find(
            (textBlock) => textBlock.position === "custom-domain-thank-you",
        )?.text;

        const fieldsData = JSON.parse(
            localStorage.getItem("dynamicFields") as string,
        ) as FieldsState | null;
        handleFormAnswers();

        const [widgets, successMessageArrayAfterSplitting] = extractWidgets(
            customDomainThankYouMessage
                ? decodeFieldsFromText(customDomainThankYouMessage, fieldsData)
                : form
                ? decodeFieldsFromText(form.successMessage, fieldsData)
                : "",
        );
        setWidgets(widgets ? widgets : null);
        setSuccessMessageArray(successMessageArrayAfterSplitting as string[]);
    }, []);

    useBingEnhancedConversion();

    if (form || customThankYouMessage) {
        return (
            <>
                <div
                    className={`${styles["thank-you-page"]} ${
                        inForm ? "" : `min-h-[85vh]`
                    } pb-10`}
                >
                    <div className="">
                        {customThankYouMessage ? (
                            <div id="thank-you-page" className={className}>
                                {customThankYouMessage}
                            </div>
                        ) : (
                            <div
                                className={
                                    thankYouOffers &&
                                    thankYouOffers.length &&
                                    (!widgets || widgets.length === 0) &&
                                    !inForm
                                        ? "flex justify-center items-start flex-col md:flex-row"
                                        : ""
                                }
                            >
                                <div
                                    id="thank-you-page"
                                    className={
                                        (thankYouOffers &&
                                        thankYouOffers.length &&
                                        (!widgets || widgets.length === 0) &&
                                        !inForm
                                            ? "w-full md:w-2/4 lg:w-2/3"
                                            : "") + ` ${className ?? ""}`
                                    }
                                >
                                    {successMessageArray.map(
                                        (content, index) => {
                                            const finalContent =
                                                removeParagraphById(
                                                    content,
                                                    eb,
                                                );
                                            return (
                                                <div key={index}>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: finalContent,
                                                        }}
                                                    ></div>
                                                    {widgets?.[index] ? (
                                                        <Widget
                                                            type={getWidgetType(
                                                                widgets[index],
                                                            )}
                                                        />
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            );
                                        },
                                    )}
                                </div>
                                {!inForm &&
                                (!widgets || widgets.length === 0) ? (
                                    <OffersColumn />
                                ) : (
                                    <></>
                                )}
                            </div>
                        )}
                    </div>

                    {!inForm &&
                    !isPageBuilder &&
                    insuranceListingHeader &&
                    clicksOffers &&
                    clicksOffers.length &&
                    !clicksOffers[0].isMortgage ? (
                        <h2 className="my-4 pt-10 font-bold text-center text-lg md:text-xl">
                            {insuranceListingHeader}
                        </h2>
                    ) : (
                        <></>
                    )}

                    {clicksOffers && !inForm && !isPageBuilder && (
                        <div className="mt-10 md:mt-5 w-full">
                            {clicksOffers
                                // ?.slice(0, 2)
                                ?.map((clickOffer, index) =>
                                    !clickOffer.isMortgage ? (
                                        <InsuranceOffer
                                            key={index}
                                            index={index}
                                            item={clickOffer}
                                            category="Auto Insurance"
                                            state={visitDetails?.region}
                                            primaryColor="#f79626"
                                            textColor="#266199"
                                            btnLabel="I Want That"
                                        />
                                    ) : (
                                        <MortgageOffer
                                            key={index}
                                            // index={index}
                                            offer={clickOffer}
                                        />
                                    ),
                                )}
                        </div>
                    )}

                    {secondListing &&
                    secondListing?.offers?.length &&
                    !inForm ? (
                        renderList(secondListing)
                    ) : (
                        <></>
                    )}
                    {thankyouListing &&
                    thankyouListing?.offers?.length &&
                    !inForm ? (
                        renderList(thankyouListing)
                    ) : (
                        <></>
                    )}
                </div>
            </>
        );
    }

    return <></>;
}
